import React from "react"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import {Container, Row, Col} from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PortfolioItemTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      <Container id="portfolio" className="py-5 mt-5">
        <Row>
          <Col xs={{'size': 12, 'offset': 0}} sm={{'size': 10, 'offset': 1}}>
          
            <article itemScope itemType="http://schema.org/Article">
              <header className="mb-4">
                <div className="header__details">
                  <div className="header__avatar">
                    <Img alt={post.frontmatter.title} fluid={post.frontmatter.avatar.childImageSharp.fluid} className="rounded-circle" />
                  </div>
                  <div className="header__titles">
                    <h1>{post.frontmatter.title}</h1>
                    <h2>{post.frontmatter.subtitle}</h2>
                  </div>
                </div>
                <div className="header__figure">
                  <Img alt={post.frontmatter.title} fluid={post.frontmatter.header.childImageSharp.fluid} />
                </div>
              </header>
              <Row>
                <Col md="7">
                  <section
                    dangerouslySetInnerHTML={{ __html: post.html }}
                    itemProp="articleBody"
                    className="blogArticleBody"
                  />
                </Col>
                <Col md="5">
                  <aside className="portfolioSidebar">
                    <h5>Details</h5>
                    <ul>
                      {post.frontmatter.details.map((detail, index) => (
                          <li key={index}>
                              {detail}
                          </li>
                      ))}
                    </ul>
                    {post.frontmatter.projecturl &&
                      <a href={post.frontmatter.projecturl} className="btn btn-primary btn-block" rel="noreferrer" target="_blank">View Live Project</a>
                    }
                  </aside>
                </Col>
              </Row>

            </article>

          </Col>
        </Row>
      </Container>





    </Layout>
  )
}

export default PortfolioItemTemplate

export const pageQuery = graphql`
  query PortfolioItemBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(
        fields: { slug: { eq: $slug } }
      ) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        description
        details
        projecturl
        header{
          childImageSharp{
            fluid(maxWidth:1600, quality:100){
              ...GatsbyImageSharpFluid
            }
          }
        }
        avatar{
          childImageSharp{
            fluid(maxWidth:50, quality:100){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
